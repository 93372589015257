import React from 'react'
import Layout from "../components/layout";

const Privacidad = () =>{
    return (
        <Layout>
            <h1>Política de Privacidad</h1>
            <p>Los datos recolectados serán usados para verificar su reservación. Los datos se almacenan en una base de datos de acceso público</p>
            <p>Al autorizar el ingreso con su identidad de Google permite su autentificación como colaborador del Área de Conservación Guanacaste y se utilizará su usuario para registrar sus acciones en el sistema</p>
        </Layout>
    )
}

export default Privacidad
